import request from '@/utils/request';
// 登录
export function login(loginForm) {
  return request({
    url: '/user/login',
    method: 'post',
    data: loginForm
  })
}
// 手机短信 /user/phone/verification/login
export function getUserByToken(data){
  return request({
    url: '/user/get_user_by_token',
    method: 'post',
    data: data
  })
}
export function generateCode(data) {
  return request({
    url: '/user/phone/verification',
    method: 'post',
    data
  })
}

// /user/phone/verification   手机号登录
export function postPhoneVerification(data) {
  return request({
    url: '/user/phone/verification/login',
    method: 'post',
    data
  })
}

// phone/verification/code 手机号注册校验
export function register(data) {
  return request({
    url: '/user/phone/verification/code',
    method: 'post',
    data
  })
}

// 通过邮箱获取验证码
export function getCodeByEmail(data) {
  return request({
    url: '/verify/generateCode',
    method: 'post',
    data
  })
}

// 获取地图坐标点信息
export function getMapInfo(data) {
  return request({
    url: '/product/get/contractor/list',
    method: 'post',
    data
  })
}
// 邮箱注册
export function postAddUser(data) {
  return request({
    url: '/user/add/user',
    method: 'post',
    data
  })
}
// 获取产品列表数据 
export function getProductList(data) {
  return request({
    url: '/product/get/product/list',
    method: 'get',
    data
  })
}
// 获取产品列表推荐 
export function getProductListByType(data) {
  return request({
    url: '/product/get/product/other/desc',
    method: 'post',
    data
  })
}
// 加入购物车//get params传参
export function addCart(data) {
  return request({
    url: '/order/add/cart',
    method: 'post',
    data
  })
}
// 发货详情
export function PostProductListByType(data) {
  return request({
    url: '/userShippingAddress/addUserShippingAddress',
    method: 'post',
    data
  })
}
// 获取产品详情 
export function GetProductByProductId(data) {
  return request({
    url: '/product/get_product_detail',
    method: 'post',
    data
  })
}
// 账单地址 
export function PostUserBillingAddress(data) {
  return request({
    url: '/userBillingAddress/addUserBillingAddress',
    method: 'post',
    data
  })
}
// 个人中心_我的订单
export function PostOrder(data) {
  return request({
    url: '/order/addOrder',
    method: 'post',
    data
  })
}
// userld 登录用户ID 显示商品 My Cart
export function getCart(data) {
  return request({
    url: '/order/get/cart',
    method: 'post',
    data
  })
}
// 购物车数量计算
// export function getCartNumber(data){
//   return request({
//     url: '/order/get/cart/number',
//     method: 'post',
//     data
//   })
// }
// remove.cart.product
// 删除购物车商品
export function removeCartProduct(data) {
  return request({
    url: '/order/remove/cart/product',
    method: 'post',
    data
  })
}
//survey/getSurveyListByUserId?userId=a18830fe-c2f9-4907-a4b6-41c35fa21537
export function getSurveyListByUserId(params) {
  return request({
    url: '/survey/getSurveyListByUserId',
    method: 'get',
    params: params
  })
}
//solution/get/solutions/list
// 获取解决方案列表 
export function getSolutionList(params) {
  return request({
    url: '/solution/get_solution_list',
    method: 'get',
    params: params
  })
}

// http://192.168.1.106:8008/api/solution/get/solutions/desc 
// 套餐页
export function getSolutionDesc(params) {
  return request({
    url: '/solution/get/solutions/desc',
    method: 'get',
    params: params
  })
}

// /api/solution/getSolutionById  套餐详情
export function postSolutionById(params){
  return request({
    url:"/solution/getSolutionById",
    method:"get",
    params
  })
}

// 获取用户信息
// https://developer.nrel.gov/api/pvwatts/v8.json
export function getPvWatts(data) {
  return request({
    url: '/pvwatts/v8.json',
    method: 'get',
    params: data
  })
}
// api_key: cAfoU2aSVaAIe5H3rxZSF4QIZCzPeodgHP9C8fHl //11个参数 现在有8个参数 少3个 一个是这个keykey就用他的
// http://192.168.1.106:8008/api/user/add/personal/config
export function addPersonalConfig(data) {
  return request({
    url: '/user/add/personal/config',
    method: 'post',
    data
  })
}
// /api/user/add/personal/config
export function postUserConfig(data) {
  return request({
    url: '/user/add/personal/config',
    method: 'post',
    params: data
  })
}

//http://192.168.1.106:8008/api/order/boa/stripe/play  stripe支付 
export function postStripePlay(data) {
  return request({
    url: '/order/boa/stripe/play',
    method: 'post',
    data
  })
}
// /api/order/boa/payment/start boa支付返回订单信息
export function postPaymentPlay(data) {
  return request({
    url: '/order/boa/payment/start',
    method: 'post',
    data
  })
}
// /order/generate/order  创建订单 
export function postGenerateOrder(data) {
  return request({
    url: '/order/generate/order',
    method: 'post',
    data
  })
}
// /order/get/order/by/id 通过id获取订单  order_type
export function getOrderById(data) {
  return request({
    url: '/order/get/order/by/id',
    method: 'post',
    data
  })
}
// order/modified/order
export function modifiedOrder(data) {
  return request({
    url: '/order/modified/order',
    method: 'post',
    data
  })
}
// api/order/get/order/list/by/user/id 我的订单
export function getOrderListByUserId(data) {
  return request({
    url: '/order/get/order/list/by/user/id',
    method: 'post',
    data
  })
}
// http://0.0.0.0:8001/api/user/user_address_list/personal/config  收货地址
export function postUserAddressList(data) {
  return request({
    url: '/user/user_address_list/personal/config',
    method: 'post',
    data
  })
}
// http://0.0.0.0:8001/api/user/user_address_save/personal/config 收货地址添加
export function postUserAddressSave(data) {
  return request({
    url: '/user/user_address_save/personal/config',
    method: 'post',
    data
  })
}


// http://192.168.1.106:8008/api/order/post/addr/about/order  //账单地址
export function postAddrAboutOrder(data) {
  return request({
    url: '/order/post/addr/about/order',
    method: 'post',
    data
  })
}
// http://0.0.0.0:8001/api/user/user_address_save/personal/config
export function postUserAddressSave1(data) {
  return request({
    url: '/user/user_address_save/personal/config',
    method: 'post',
    data
  })
}
// http://192.168.1.106:8008/api/blog/home  导航栏 
export function getBlogHome(params) {
  return request({
    url: '/blog/home',
    method: 'get',
    params
  })
}

// http://192.168.1.106:8008/api/blog/router/path 路由数据动态化
export function postRouterPath(data) {
  return request({
    url: '/blog/router/path',
    method: 'post',
    data
  })
}
// http://192.168.1.106:8008/api/product/get/discount 商品折扣信息
export function getDiscount(params) {
  return request({
    url: '/product/get/discount',
    method: 'get',
    params
  })
}

// http://192.168.1.106:8008/api/product/get/full/minus  商品满减信息
export function getFullMinus(params) {
  return request({
    url: '/product/get/full/minus',
    method: 'get',
    params
  })
}

// http://192.168.1.106:8008/api/user/message/lookout   站内获取信息 
export function postMessage(data) {
  return request({
    url: '/user/message/lookout',
    method: 'post',
    data
  })
}
// http://192.168.1.106:8008/api/user/message/lookout/read 站内已读
export function postMessageRead(data) {
  return request({
    url: '/user/message/lookout/read',
    method: 'post',
    data
  })
}
// http://192.168.1.66:8008/api/blog/article/get_article_content 通过id获取article内容
export function getArticleContent(params) {
  return request({
    url: '/blog/article/get_article_content',
    method: 'get',
    params
  })
}
// /api/blog/carousel/get_carousel_list 轮播图
export function postCarouselList(data) {
  return request({
    url: '/blog/carousel/get_carousel_list',
    method: 'post',
    data
  })
}
// 轮播图无导航
export function postCarouselListPath(data) {
  return request({
    url: '/blog/carousel/get_carousel_by_path',
    method: 'post',
    data
  })
}

// customer_calculate/inter/nice/miss/z5y6q8dc 计算器
export function postCalculate(data) {
  return request({
    url: '/blog/customer-calculate',
    method: 'post',
    data
  })
}

// 修改密码 
export function changeCustomerPassword(data) {
  return request({
    url: '/user/change_customer_password',
    method: 'post',
    data
  })
}



// /getStateList/accept/inter/nice/miss/z5y6q8dc 计算器页州推荐
export function postSolarAdmin(data) {
  return request({
    url: '/blog/get_state_list_api',
    method: 'post',
    data
  })
}
// user/addUserConfig
export function  postUserConfig1(data) {
  return request({
    url: '/user/addUserConfig',
    method: 'post',
    data
  })
}
// /api/blog/customer-calculate 客户推荐
export function postCustomerCalculate(data) {
  return request({
    url: '/blog/recommend_solution',
    method: 'post',
    data
  })
}
// /api/solution/get/state_solution  州映射发电量接口
export function postStateSolution(data){
  return request({
    url:"/solution/get/state_solution",
    method:"post",
    data
  })
}
// /user/get-policy-byid/inter/nice/miss/z5y6q8dc  物流协议接口 
export function postPolicyById(data){
  return request({
    url:"/user/get-policy-byid/inter/nice/miss/z5y6q8dc",
    method:"post",
    data
  })
}

// order/get/product/by/id 通过id获取产品
export function postProductById(data){
  return request({
    url:"/order/get/product/by/id",
    method:"post",
    data
  })
}
// /product/product_detail_list/acctes/inter/contractor/list  套餐商品详情接口
export function PostProductDetailList(data){
  return request({
    url:"/order/get/products/by/solution_id",
    method:"post",
    data
  })
}
// /blog/get_state_policies 前台展示-获取州政策上限数据接口  
export function postStatePolicies(data){
  return request({
    url:"/blog/get_state_policies",
    method:"post",
    data
  })
}

export function checkAnnounce(data){
  return request({
    url:"/order/check_stripe_announce",
    method:"post",
    data
  })
}
// generates/solution_order 套餐生成订单
export function postSolutionOrder(data){
  return request({
    url:"/order/generates/solution_order",
    method:"post",
    data
  })
}
// /api/blog/submit_quote Contact Us 提交表单
export function postSubmitQuote(data){
  return request({
    url:"/blog/submit_quote",
    method:"post",
    data
  })
}
// /user/email_verification_code_login 邮箱验证码校验登录
export function postEmailVerificationCodeLogin(data){
  return request({
    url:"/user/email_verification_code_login",
    method:"post",
    data
  })
}
// /user/phone verification code login 手机号验证码校验登录
export function postPhoneVerificationCodeLogin(data){
  return request({
    url:"/user/phone_verification_code_login",
    method:"post",
    data
  })
}

// /api/order/remove/order/by/id删除order订单
export function postRemoveOrderById(data){
  return request({
    url:"/order/remove/order/by/id",
    method:"post",
    data
  })
}

// /api/user/add_wish_list 添加心愿单  
export function postAddWishList(data){
  return request({
    url:"/user/add_wish_list",
    method:"post",
    data
  })
}
// /api/user/del_wish_product 删除心愿单
export function postDelWishProduct(data){
  return request({
    url:"/user/del_wish_product",
    method:"post",
    data
  })
}
// getProductListData 获取产品列表数据
export function getProductListData(data){
  return request({
    url:"/product/get_product_list_data",
    method:"post",
    data
  })
}
// /api/order/generate_order_with_form_data   
export function postGenerateOrderWithFormData(data){
  return request({
    url:"/order/generate_order_with_form_data",
    method:"post",
    data
  })
}

// order/get_product_list_data_by_order_id 获取订单列表数据 
export function getProductListDataByOrderId(data){
  return request({
    url:"/order/get_product_list_data_by_order_id",
    method:"post",
    data
  })
}
// /api/order/generate_order_solution_product 生成订单产品 
export function postGenerateOrderSolutionProduct(data){
  return request({
    url:"/order/generate_order_solution_product",
    method:"post",
    data
  })
}
// /api/blog/get_web_config  
export function getWebConfig(data){
  return request({
    url:"/blog/get_web_config",
    method:"post",
    data
  })
}
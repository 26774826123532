<template>
  <div class="table-container">
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      header-align="center"
      default-expand-all
      @selection-change="handleSelectionChange"
      @expand-change="handleExpandChange"
    >
      <el-table-column type="expand" width="55" v-if="isExpand">
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.order_detail"
            :key="`ship-pay-${index}`"
            class="ship-pay-group"
          >
          <!-- <div style="margin-left: 10px">{{ scope.row.order_id }}</div> -->
            <ProductListDetail
              class="ship-pay"
              :data="item.product"
              :dataNew="item"
              :activeAddress="item"
              :showAddress="false"
            ></ProductListDetail> 
          </div>
        </template>
      </el-table-column>
      <el-table-column type="selection" width="55" v-if="isMultiple">
      </el-table-column>

      <el-table-column type="index" width="80" label="ID" align="center" :index="indexMethod">
      </el-table-column>
      <el-table-column
        v-for="(item, index) in tableColumns"
        :key="`table-${index}`"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :sortable="item.sortable"
        align="center"
      >
        <template slot-scope="scope">
          <template v-if="item.type === 'link'">
            <el-button
              type="text"
              @click="handleClick(scope.row, scope.row[item.prop], item.prop)"
              >{{ scope.row[item.prop] }}</el-button
            >
          </template>
          <template v-else-if="item.type === 'button' && scope.row.order_status <= 0">
            <el-button
              type="text"
              @click="deleteClick(scope.row, scope.row[item.prop], item.prop)"

              >
              <i class="iconfont icon-delete deleteOrder"></i>
            </el-button>
          </template>
          <template v-else-if="item.type === 'status'">
            <el-tag effect="dark" :type="scope.row[item.prop]">
              {{ scope.row.statusValue }}
            </el-tag>
          </template>
          <template v-else>{{ scope.row[item.prop] }}</template>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" v-if="page.total">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="pageConfig.pageSizes"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import ProductListDetail from "./ProductListDetail.vue";

export default {
  components: {
    ProductListDetail,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isExpand: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Object,
      default: () => {
        return {
          pageSizes: 10,
          currentPage: 1,
          total: 0,
        };
      },
    },
    pageConfig: {
      type: Object,
      default: () => {
        return {
          pageSizes: [10, 20],
        };
      },
    },
  },
  data() {
    return {
      disabled: false,
      tableHeight: "calc(100% - 52px)"
    };
  },
  mounted(){
    // console.log(this.tableData,"comtable_mounted")
  },
  watch: {
    // 监听父组件的 `parentData` prop 变化
    tableData(newValue) {
      this.tableData = newValue;
    },
    
  },
  methods: {
    indexMethod(index) {
      return (this.page.currentPage - 1) * this.page.pageSizes + index + 1;
    },
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
    handleClick(row, value, prop) {
      // 传递数据到目标页面
      // this.$router.push({
      //   path: value,
      //   query: {
      //     data: JSON.stringify(row)
      //   }
      // });
      this.$emit("handleClick", row, value, prop);
    },
    deleteClick(row, value, prop) {
      this.$emit("deleteClick", row, value, prop);
    },
    handleSelectionChange(val) {
      this.$emit("handleSelectionChange", val);
    },
    handleExpandChange(row) {
      this.$emit("handleExpandChange", row);
    },
  },
};
</script>

<style lang="less" scoped>
.deleteOrder {
  font-size: 30px;
}
.table-container {
  padding: 20px;
  background: #fff;
  display: flex;
  height: 100%;
  min-height: 600px;
  flex-flow: column;
  .el-table {
    flex: 1;
    overflow: hidden;
  }
  .pagination {
    padding-top: 20px;
  }
  ::v-deep {
    .el-pagination__sizes .el-input .el-input__inner:hover {
      border-color: #c0c4cc;
    }
    .ascending {
      .caret-wrapper {
        .ascending {
          border-bottom-color: #7eb63b;
        }
      }
    }
    .descending {
      .caret-wrapper {
        .descending {
          border-top-color: #7eb63b;
        }
      }
    }
  }
}
</style>
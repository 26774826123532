<template>
  <div id="app" class="all-page-container">
    <HeadersList />
    <router-view></router-view>
    <!-- <template v-else>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </template> -->
    <FooterList />
</div>
</template>

<script>
// import router from "@/router/index";
import PageSlot from "@/views/PageSlot.vue";
export default {
  name: "App",
  components: { PageSlot },
  mounted(){
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

      this.$store.commit('setIsMobile', isMobile);
      
  }
  // computed: {
  //   showHeader() {
  //     const options = router.options.routes.filter(
  //       (item) => item.meta?.hasHeader
  //     );
  //     const op = options.find((item) => item.name === this.$route.name);
  //     return !!op
  //   },
  // },
};
</script>
<style>
/* 你的样式 */
.rc-anchor-container {
  display: none;
}
</style>
<template>
  <div class="shipping-detail-container">
    <!-- <div class="shipping-header" v-if="isDesignOrderPath">
      <div class="shipping-title">Intention package:{{ data.solution.name }}</div>
      <div v-if="isDesignOrderPath">
          <el-button type="text" @click="outerVisible = true">
            <span class="iconfont icon-jisuanqi" style="font-size: 20px;"> </span>
            <span>Package combination and overlay</span>
          </el-button>
          <el-dialog title="Package combination and overlay" :visible.sync="outerVisible">
            <div>
                计算
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="outerVisible = false">取 消</el-button>
              <el-button type="primary" @click="outerVisible = false">确定</el-button>
            </div>
          </el-dialog>
        </div> -->
      <!-- <el-button type="text">Edit Shipping Details</el-button> -->
    <!-- </div> -->
     
    <div class="shipping-body">
      <div class="shipping-image">
        <div class="shipping-image-bg">
          <img :src="data.thumbnail" />
        </div>
      </div>
      <div class="shipping-content">
        <!-- <div class="shipping-items solution-name">
             <div>Intention package:{{ data.solution.name }}</div>
        </div> -->
        <div class="shipping-items is-bold">
          <div>{{ data.name }}</div>
          <div :class="data.discount ? 'is-discount' : ''">
            $ {{ data.univalent }}
          </div>
        </div>
     
        <div class="shipping-items">
          <div>Product Number:{{ data.product_id }}</div>
          <div v-if="data.discount" style="font-weight: bold; color: #333; font-size: 14px;">
            ${{ data.univalent - data.discount }}
          </div>
   
        </div>
        <div class="shipping-items">
          <div>Get it by {{ formatDate(data.update_time) }}</div>
          <div v-if="data.discount">
            Saving:${{ data.discount }}({{ parseInt(data.discountPercent) }}%)
          </div>
          <!-- <div>eCoupon Applied:<span class="is-bold">SPRINGVISON</span></div> -->
        </div>
        <div class="shipping-items">
          <div v-if="dataNew.product_count">Qty {{ dataNew.product_count }}</div>
          <div v-if="data.product_count">Qty {{ data.product_count }}</div>
          <!-- <div>Qty {{ data }}</div> -->

          <!-- <div>eCoupon limited to 3 units only</div> -->
        </div>
      </div>
    </div>
    <div class="shipping-footer" v-if="showAddress">
      <div class="shipping-footer-title">Shipping Address</div>
      <div class="footer-items">
        <div>{{ data.first_name }} {{ data.last_name }}</div>
        <div>
          {{ data.address
          }}<span v-if="data.building"> {{ data.building }}</span>
        </div>
        <div>
          <span v-if="data.state">{{ data.state }},</span
          >{{ data.city }} Carolina
          <span v-if="data.zip_code">{{ data.zip_code }}</span>
        </div>
        <div>{{ data.email }}</div>
        <div>{{ data.phone }}</div>
      </div>
      <div class="footer-button-group">
        <!-- <el-button type="text" @click="handleClick(1)"
          >Edit Shipping Address >
        </el-button> -->
        <!-- <transition name="el-fade-in-linear">
          <el-button type="text" v-show="activeName" @click="showDialog('Add')"
            >Add New Address</el-button
          >
        </transition> -->
      </div>
    </div>
    <!-- <el-collapse v-model="activeName" accordion>
      <el-collapse-item title="" name="1">
        <div class="address-group-container" v-if="listGroup.length">
          <div
            class="address-main"
            v-for="(item, index) in listGroup"
            :key="item.id"
          >
            <div class="address-content">
              <div
                @click="setActiveAddress(item, index)"
                :class="[
                  'address-header',
                  activeIndex === index ? 'is-active' : '',
                ]"
              >
                <CheckTextHidden
                  :lines="lines"
                  :desc="`${item.first_name} ${item.last_name}`"
                ></CheckTextHidden>
              </div>
              <div class="address-body">
                <div class="address-body-content">
                  <div v-if="item.state">{{ item.state }}</div>
                  <div>{{ item.city }}</div>
                  <div>{{ item.address }}</div>
                  <div v-if="item.state">{{ item.building }}</div>
                </div>
                <div class="phone-number">{{ item.phone }}</div>
                <div class="default-address" v-if="item.is_default">
                  Default Address
                </div>
              </div>
            </div>
            <div class="btn-group">
              <div class="btn-flex">
                <el-button
                  type="text"
                  v-if="!item.is_default"
                  @click="setDefaultAddress(item)"
                  >Set As Default Address</el-button
                >
                <el-button type="text" @click="showDialog('Edit', item)"
                  >Edit</el-button
                >
                <el-popconfirm
                  title="Are you sure you want to delete the Item?"
                  @confirm="handleDelete(item)"
                >
                  <el-button
                    type="text"
                    slot="reference"
                    v-if="!item.is_default"
                    >Remove</el-button
                  >
                </el-popconfirm>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="1000px"
      :before-close="handleClose"
    >
      <FormItemsGroup
        :formData="formData"
        labelWidth="150px"
        :rules="rules"
        ref="form"
        showBtns
      ></FormItemsGroup>
    </el-dialog> -->
  </div>
</template>
<script>
import FormItemsGroup from "@/components/FormItemsGroup.vue";
import CheckTextHidden from "@/components/CheckTextHidden.vue";
import { addAddress, getAddress, deleteAddress, editAddress } from "@/api/chat";
import Cookies from "js-cookie";
export default {
  components: { FormItemsGroup, CheckTextHidden },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          total: 0,
          totalSave: 0,
          ship: 0,

        };
      },
    },
    dataNew: {
      type: Object,
      default: () => {
        return {
          total: 0,
          totalSave: 0,
          ship: 0,

        };
      },
    },
    showAddress: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    var validateNumber = (rule, value, callback) => {
      if (!Number.isInteger(Number(value))) {
        callback(new Error("Please enter a numerical value"));
      } else {
        callback();
      }
    };
    return {
      lines: 1,
      activeName: "1",
      listGroup: [],
      user: this.getUserInfoFromCookie(),
      activeIndex: 0,
      title: "",
      visible: false,
      formData: [
        // {
        //   type: "input",
        //   prop: "country",
        //   default: "",
        //   label: "Country/Area",
        // },
        {
          type: "input",
          prop: "first_name",
          default: "",
          label: "First Name",
        },
        {
          type: "input",
          prop: "last_name",
          default: "",
          label: "Last Name",
        },
        {
          type: "input",
          prop: "address",
          default: "",
          label: "Address",
        },
        {
          type: "input",
          prop: "building",
          default: "",
          label: "Apt,suite,Building",
        },
        {
          type: "input",
          prop: "zip_code",
          default: "",
          label: "Zip code",
        },
        {
          type: "input",
          prop: "city",
          default: "",
          label: "City or Township",
        },
        {
          type: "input",
          prop: "state",
          default: "",
          label: "State",
        },
        {
          type: "input",
          prop: "company_name",
          default: "",
          label: "Company name",
        },
        {
          type: "input",
          prop: "email",
          default: "",
          label: "email",
        },
        {
          type: "input",
          prop: "phone",
          default: "",
          label: "phone",
        },
        {
          type: "input",
          prop: "ext",
          default: "",
          label: "ext",
        },
        {
          type: "checkbox",
          prop: "is_default",
          default: "",
          wholeLine: true,
          options: [
            {
              value: false,
              label: "Set as Default Shipping Address",
            },
          ],
        },
      ],
      rules: {
        first_name: [
          {
            required: true,
            message: "First Name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Last Name is required",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "Address is required",
            trigger: "blur",
          },
        ],
        zip_code: [
          {
            required: true,
            message: "Zip code is required",
            trigger: "blur",
          },
          { validator: validateNumber, trigger: "blur" },
        ],
        city: [
          {
            required: true,
            message: "City or Township is required",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Address is required",
            trigger: "blur",
          },
          {
            type: "email",
            message: "please enter the correct email address",
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            message: "phone is required",
            trigger: "blur",
          },
          { validator: validateNumber, trigger: "blur" },
        ],
      },
      outerVisible: false,
      innerVisible: false,
    };
  },
  mounted() {
    //  console.log("this.data,mounted",this.data);
  },
  computed: {
    isDesignOrderPath() {
      return this.$route.path == "/designOrder";
    },
  },
  watch: {
    // 监听父组件的 `parentData` prop 变化
    data(newValue) {
      //this.data = newValue;
      // console.log("this.data",this.data)
    },
    
  },
  created() {
    // console.log(this.data, "data");
    this.$emit('power-updated', this.data.power);
    this.$emit('has_battery-updated', this.data.has_battery);
  },
  methods: {
    getUserInfoFromCookie() {
    const userInfoCookie = Cookies.get('userInfo');
    if (userInfoCookie) {
      try {
        // URL 解码
        const decodedCookie = decodeURIComponent(userInfoCookie);
        // 解析 JSON 
        const userInfo = JSON.parse(decodedCookie);
        return userInfo;
      } catch (error) {
        console.error('Failed to parse userInfo cookie:', error);
        return null;
      }
    }
    return null;
  },
    setActiveAddress(item, index) {
      if (this.activeIndex !== index) {
        this.activeIndex = index;
        // this.activeAddress = item;
        // localStorage.setItem("activeAddress", JSON.stringify(item));
        this.$emit("handleAddress", item);
      }
    },
    setDefaultAddress(form) {
      const params = {
        ...form,
        is_default: true,
      };
      this.editAddress(params);
    },
    showDialog(title, form = {}) {
      this.visible = true;
      this.title = `${title} Address`;
      this.$nextTick(() => {
        this.$refs.form.form = { ...form, is_default: false };
      });
    },
    handleSave(form) {
      const params = {
        ...form,
        user_id: this.user.uuid,
      };
      if (form.id) {
        this.editAddress(params);
      } else {
        this.addAddress(params);
      }
    },
    handleClose() {
      this.$refs.form.$refs.form.clearValidate();
      this.visible = false;
    },
    async addAddress(params) {
      const res = await addAddress(params);
      if (res?.data?.code == 200) {
        this.$message.success(res.data.msg);
        this.getAddress();
        this.visible = false;
      } else {
        this.$message.error(res.data.msg);
      }
    },
    async editAddress(params) {
      const res = await editAddress(params);
      if (res?.data?.code == 200) {
        this.$message.success(res.data.msg);
        this.getAddress();
        this.visible = false;
      } else {
        this.$message.error(res.data.msg);
      }
    },
    async handleDelete(item) {
      const params = {
        id: item.id,
      };
      const res = await deleteAddress(params);
      if (res?.data?.code == 200) {
        this.$message.success(res.data.msg);
        this.getAddress();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    async getAddress() {
      const params = {
        user_id: this.user.uuid,
      };
      const res = await getAddress(params);

      if (res?.data?.code == 200) {
        this.listGroup = res.data.data;
        const index = this.listGroup.findIndex((item) => item.is_default);
        const activeIndex = index >= 0 ? index : 0;
        this.activeIndex = activeIndex;
        // this.activeAddress = this.listGroup[activeIndex];
        this.$emit("handleAddress", this.listGroup[activeIndex]);
        // localStorage.setItem(
        //   "activeAddress",
        //   JSON.stringify(this.listGroup[index])
        // );
      } else {
        // this.$message.error(res.data.msg);
      }
    },
    handleBuyNow() {
      this.$emit("handleBuyNow");
    },
    handlePayPal() {
      this.$emit("handlePayPal");
    },
    handleAmazonPay() {
      this.$emit("handleAmazonPay");
    },
    formatDate(dateString) {
      if (!dateString) {
        return "N/A"; // 或者其他默认值
      }
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.error(`Invalid date string: ${dateString}`);
        return "Invalid Date";
      }
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    handleClick(code) {
      this.$emit("handleClick", code);
      // this.activeName = this.activeName === "1" ? "" : "1";
    },
  },
};
</script>
<style lang="less" scoped>
.el-radio__original {
    display: none !important; /* 隐藏原生 radio 输入,但仍然允许交互 */
}
.solution-name{
  font-size: 0.85rem;
}
::v-deep .el-form {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  .el-form-item {
    width: calc(50% - 15px);
  }
}
.footer-button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.shipping-detail-container {
  background: #fff;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  .shipping-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    .shipping-title {
      font-size: 16px;
      // font-weight: bold;
    }
  }
  .shipping-body {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
    padding: 10px 20px 12px;
    .shipping-image{
      width: 120px;
      height: 150px;
      display: flex;
      align-items: center;
      .shipping-image-bg {
        height: 100%;
          img {
            display: block;
            width: 120px;
            height: 100%;
          }
    }
    }

    .shipping-content {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-flow: column;
      font-size: 12px;
      padding-top: 12px;
      row-gap: 6px;
      .shipping-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666;
        b {
          color: #333;
          font-size: 16px;
        }
        .is-discount {
          font-weight: normal;
          color: #666;
          font-size: 14px;
          text-decoration: line-through;
        }
      }
      .is-bold {
        font-weight: bold;
        color: #333;
        font-size: 16px;
      }
    }
  } 
  .shipping-footer {
    padding: 12px 20px;
    border-top: 1px solid #dcdcdc;
    line-height: 24px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    row-gap: 10px;
    .shipping-footer-title {
      font-weight: bold;
      // margin-bottom: 10px;
    }
  }
  .el-icon-arrow-down {
    transition: transform 0.3s;
    &.is-active {
      transform: rotate(180deg);
    }
  }
  ::v-deep {
    .el-collapse,
    .el-collapse-item__wrap {
      border: 0;
    }
    .el-collapse-item__content {
      padding-bottom: 0;
    }
    .el-collapse-item__header {
      display: none;
    }
  }
  .address-group-container {
    padding: 0 20px 20px;
    .address-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;
      margin-bottom: 6px;
      overflow: hidden;
      &:hover {
        background: #fff3f3;
        .btn-group {
          display: block;
        }
      }
    }
    .btn-group {
      display: none;
      .btn-flex {
        display: flex;
        align-items: center;
        column-gap: 6px;
        .el-button {
          padding: 0 6px;
        }
      }
    }
    .address-content {
      display: flex;
      align-items: center;
      column-gap: 12px;

      .address-header {
        border: 1px solid #ddd;
        width: 120px;
        height: 30px;
        padding: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        column-gap: 7px;
        box-sizing: border-box;
        background: #fff;
        position: relative;
        overflow: hidden;
        font-size: 12px;
        line-height: 28px;

        &:hover {
          border: 2px solid #7eb63b;
        }
        &.is-active {
          border: 2px solid #7eb63b;
          &::after {
            content: "";
            display: block;
            width: 22px;
            height: 22px;
            background: #7eb63b;
            position: absolute;
            right: -11px;
            bottom: -11px;
            transform: rotate(45deg);
          }
        }
      }
      .address-body,
      .address-body-content {
        display: flex;
        align-items: center;
        div {
          white-space: nowrap;
        }
      }
      .address-body {
        column-gap: 12px;
      }
      .address-body-content {
        column-gap: 7px;
      }
      .default-address {
        background: #999;
        padding: 0 6px;
        color: #fff;
      }
    }
  }
}
</style>

<template>
  <div class="chat-container">
    <transition name="el-fade-in-linear">
      <div class="chart-icon" v-show="showBtn" @click="showDrawer">
        <img src="../assets/images/botAvatar3.png" alt="touxiang" />
      </div>
    </transition>
    <transition name="el-fade-in-linear">
      <div class="chat-widget" v-show="!showBtn">
        <div class="chat-header">
          <div class="chat-title">Connect Us</div>
          <div class="header-right">
            <i class="el-icon-minus" @click="handleCommand('Small')"></i>
            <el-dropdown trigger="click" @command="handleCommand">
              <i class="el-icon-more"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Clear">Clear</el-dropdown-item>
                <el-dropdown-item command="Restart">Restart</el-dropdown-item>
                <el-dropdown-item command="Close">Close</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="chat-body">
          <ChatBody
            ref="ChatBody"
            :chatData="chatData"
            @submitForm="submitForm"
          ></ChatBody>
        </div>
        <div class="chat-footer">
          <ChatFooter @submitForm="submitForm"></ChatFooter>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ChatBody from "./components/ChatBody.vue";
import ChatFooter from "./components/ChatFooter.vue";
import { webhook, webhooks } from "@/api/chat";
import { v4 as uuidv4 } from "uuid";
import { EventBus } from "../utils/eventBus"; // 引入事件总线

export default {
  components: { ChatBody, ChatFooter },
  data() {
    return {
      chatData: [],
      showBtn: true,
    };
  },
  mounted() {
    this.reset();
    // 监听事件总线中的事件
    EventBus.$on("showChat", () => {
      this.showDrawer();
    });
  },
  methods: {
    async reset() {
      const params = {
        next_action: "action_session_start",
        tracker: { sender_id: uuidv4() },
      };
      const res = await webhook(params);
      if (res?.data?.responses?.length) {
        const responses = res.data.responses;
        responses.forEach((item) => {
          item.isCustomer = true;
          if (item?.custom?.data) {
            item.list = item.custom.data;
          }
        });
        this.chatData = responses;
      } else {
        this.chatData = [];
      }
    },
    showDrawer() {
      this.showBtn = false;
      this.$nextTick(() => {
        this.$refs.ChatBody.init();
      });
    },
    handleCommand(val) {
      if (val === "Small" || val === "Close") {
        this.showBtn = true;
      }
      if (val === "Clear") {
        this.chatData = [];
      }
      if (val === "Restart") {
        this.reset();
      }
    },
    async submitForm(text, flag) {
      this.chatData.push({ text, isImage: flag === "image" });
      this.chatData.push({ isCustomer: true });
      const url = `<img src=\"${text}" class=\"file-preview-image\" />`;
      const params = {
        message: flag === "image" ? url : text,
        sender: uuidv4(),
      };
      const res = await webhooks(params);
      const message = res?.data?.length ? res.data[0].text : "";
      this.$set(this.chatData[this.chatData.length - 1], "text", message);
    },
  },
};
</script>


<style lang="less" scoped>
.chat-container {
  position: fixed;
  bottom: 20px;
  right: 50px;
  z-index: 2002;
  .chart-icon {
    width: 53px;
    height: 40px;
    img {
      width: 100%;
      display: block;
      height: auto;
      cursor: pointer;
    }
  }
  .chat-widget {
    width: 350px;
    right: -30px;
    height: 540px;
    bottom: 0;
    position: absolute;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.16), 0 0px 5px 0 #00000096;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    .chat-header {
      height: 60px;
      background: #7eb63b;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px 0 20px;

      .chat-title {
        font-size: 20px;
        color: #fff;
      }
      .header-right {
        display: flex;
        column-gap: 10px;
        i {
          font-size: 18px;
          font-weight: bolder;
          cursor: pointer;
          color: #fff;
        }
        .el-icon-more {
          transform: rotate(90deg);
        }
      }
    }
    .chat-body {
      flex: 1;
      overflow: hidden;
    }
    .chat-footer {
      background: white;
      height: 90px;
      width: 100%;
      padding: 5px 10px;
      border-top: 1px solid #e3e7ee;
    }
  }
}
.el-popper {
  margin-top: 10px;
  padding: 10px 0;
  .el-dropdown-menu__item {
    color: #7eb63b;
    font-weight: bold;
    &:hover {
      background: #eee;
    }
  }
}
</style>
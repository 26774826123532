import { render, staticRenderFns } from "./ComTable.vue?vue&type=template&id=095f585b&scoped=true"
import script from "./ComTable.vue?vue&type=script&lang=js"
export * from "./ComTable.vue?vue&type=script&lang=js"
import style0 from "./ComTable.vue?vue&type=style&index=0&id=095f585b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "095f585b",
  null
  
)

export default component.exports
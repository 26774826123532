<template>
  <div style="margin-top: 5rem">
    <div
      v-html="
        typeof cateContentData === 'object' && Object.keys(cateContentData).length === 0
          ? ''
          : cateContentData
      "
    ></div>

    <div v-if="isCookieVisible" class="footer_Cookie">
      <button @click="closeCookie" class="close">
        <i class="iconfont icon-guanbi"></i>
      </button>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            We use cookies and similar technologies to personalize content and
            ads and analyze our traffic. By browsing our site or by selecting
            "<i class="iconfont icon-guanbi"></i>", you agree to our use of
            cookies and our sharing of information with our partners. View the
            Easy Solar
            <u @click="privacyDialog()">Website Privacy Statement.</u>
            <el-button
              size="small"
              plain
              class="cookieButton"
              @click="closeCookie"
              >Accept All Cookies</el-button
            >
            <el-button
              plain
              class="cookieButton"
              size="small"
              @click="closeCookie"
              >Reject</el-button
            >
          </div>
        </div>
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        width="50%"
      >
        <div class="dialog_box">
          <div><button class="easySolarSupply">easySolarSupply</button></div>
          <div class="title">Information We Collect About You</div>
          <div class="content">
            We want to be transparent about the data we and our partners collect
            and how we use it, so you can best exercise control over your
            personal data. For more information, please see our Privacy Policy.
          </div>
          <div class="title">Information Our Partners Collect</div>
          <div class="content">
            We use the following partners to better improve your overall web
            browsing experience. They use cookies and other mechanisms to
            connect you with your social networks and tailor advertising to
            better match your interests. You can elect to opt-out of this
            information collection by unticking the boxes below.
          </div>
          <div class="categories">
            <span>Categories</span>
            <span class="allowed"
              >2 of 2 allowed
              <el-switch
                class="el_switch"
                v-model="allowed"
                active-color="#285d50"
                inactive-color="#ff4949"
              ></el-switch>
            </span>
          </div>
          <div class="advertising">
            <p class="advertising_box">
              <span><u class="advertising_u">Advertising</u></span
              ><span>
                <el-switch
                  class="el_switch"
                  v-model="advertising"
                  active-color="#285d50"
                  inactive-color="#ff4949"
                ></el-switch>
              </span>
            </p>
            <p>
              Our advertising partners allow us to serve you relevant
              advertising across the web, based on your interests. They can also
              be used to limit the number of times you see an advertisment as
              well as help measure the effectiveness of our advertising
              campaigns. These partners may collect information about you such
              as your browser information, location, and/or IP address. Click on
              one of the partner names to learn more.
            </p>
          </div>
          <div class="advertising">
            <p class="advertising_box">
              <span><u class="advertising_u">Analytics</u></span
              ><span>
                <el-switch
                  class="el_switch"
                  v-model="analytics"
                  active-color="#285d50"
                  inactive-color="#ff4949"
                ></el-switch>
              </span>
            </p>
            <p>
              We use analytics to improve our website by better understanding
              how often users visit the site, what pages they visit most, and
              how long they spend on our site. We'll rely on cookies and
              third-party partners to track these actions and behaviors. Click
              on one of the partner names to learn more.
            </p>
          </div>
          <div class="advertising">
            <p class="advertising_box">
              <span><u class="advertising_u">Essential</u></span
              ><span class="required"> Required </span>
            </p>
            <p>
              We rely on cookies, javascript, and other web technologies to
              serve key—or essential—elements on the site. This may include
              things like your language preferences or server-based cookies
              intended to keep our site running and operational. If disabled,
              your site experience will likely be impacted.
            </p>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="optOut" @click="closeCookie">Opt Out</el-button>
          <el-button
            class="cancel"
            type="primary"
            @click="dialogVisible = false"
            >Cancel</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { postRouterPath } from "@/api/api";
import { htmlEntitiesToHtml } from "@/utils/numberformat";
import Cookies from "js-cookie";

export default {
  components: {},
  props: {},
  data() {
    return {
      isCookieVisible: true,
      dialogVisible: false,
      newDialogVisible: false,
      allowed: true,
      advertising: true,
      analytics: true,
      numberValidateForm: {
        email: "",
        name: "",
      },
      cateContentData: {},
    };
  },
  computed: {
    isRouterPathPosted() {
      return this.$globalState.isRouterPathPosted;
    },
    lastCalledPath() {
      return this.$globalState.lastCalledPath;
    },
  },
  methods: {
    async _postRouterPath(params) {
      // 如果已经调用过且路径相同，直接返回
      if (this.isRouterPathPosted && this.lastCalledPath === params.path) {
        return;
      }
      try {
        const res = await postRouterPath(params);
        if (res && res.data && res.data.data) {
          this.cateContentData = res.data.data.content;
          let content = this.cateContentData.trim();

          const start_ends = "<p><br></p>";
          if (content.endsWith(start_ends) || content.startsWith(start_ends)) {
            content = content.replace(start_ends, "").trim();
          }

          const preCodeStart = "<pre";
          const pattern = /<pre(.+?)><code(.+?)>/; // g表示全局匹配，可多次匹配符合的内容
          const preCodeEnd = "</code></pre>";
          if (
            content.startsWith(preCodeStart) &&
            content.endsWith(preCodeEnd)
          ) {
            content = content
              .replace(pattern, "")
              .replace(preCodeEnd, "")
              .trim();
            this.cateContentData = htmlEntitiesToHtml(content);
          }
          // 更新全局状态
          this.$globalState.isRouterPathPosted = true;
          this.$globalState.lastCalledPath = params.path;
        }
      } catch (error) {
        console.error("Error posting router path:", error);
      }
    },
    dialogVisibles() {
      this.dialogVisible = true;
    },
    privacyDialog() {
      if (this.$router.currentRoute.path === "/policy/PRIVACY_POLICY") {
        return;
      }
      this.$router.push("/policy/PRIVACY_POLICY");
    },
    closeCookie() {
      this.dialogVisible = false;
      this.isCookieVisible = false; // 关闭当前对话框
      // localStorage.setItem("cookieClosed", "false");
      Cookies.set("cookieClosed", true);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    if (Cookies.get("cookieClosed")) {
      this.isCookieVisible = false;
    }
  },
  mounted() {
    if (!this.isRouterPathPosted || this.lastCalledPath !== "/footerList") {
      this._postRouterPath({ path: "/footerList" });
    }
  },
};
</script>
<style scoped>
.cookieButton {
  margin-left: 10px;
  border-style: dashed;
  /* color: #666;
  border-color: #666; */
  color: #000 !important;
  border-color: #000 !important;
}
/* .cookieButton:hover{

} */

/* 默认样式 */

.contact-btn {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.contact-desc {
  font-size: 1.2rem;
  font-weight: 100;
}

/* 移动端样式 */
@media (max-width: 768px) {
  .contact-btn {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .contact-desc {
    font-size: 1rem;
  }

  .footer-widget-title h4 {
    font-size: 1.1rem;
  }

  .footer-widget-menu ul li a {
    font-size: 0.9rem;
  }

  .solar-contact-content p {
    font-size: 0.9rem;
  }

  .solar-contact-content a {
    font-size: 0.9rem;
  }

  .copyright-area p {
    font-size: 0.8rem;
  }
  .mobile-col{
    text-align: center;
  }
  .mobile-h4{
    margin: 20px auto 0 auto;
  }
}


::v-deep .v-html {
  z-index: 1000 !important;
}
.select_states {
  padding: 10px;
}
.offer_easySolarSupply {
  color: white;
  background: #7db53b;
  border: 1px solid #7f7f7f;
  padding: 10px;
  font-size: 1rem;
  position: absolute;
  left: 0;
  top: 0;
}
.optOut {
  background: #294e95;
  color: white;
}
.cancel {
  border: 1px solid #294e95;
  color: #294e95;
}
.required {
  color: black;
  font-weight: 500;
}
.advertising_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-top: 1px solid #c1c1c1;
  height: 45px;
}
.categories {
  display: flex;
  width: 100%;
  background: #294e95;
  border-radius: 3px;
  color: white;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #7f7f7f;
}
.allowed,
.el_switch {
  display: flex;
  align-items: center;
}
.el_switch {
  margin: 13px 5px 0 5px;
}
.easySolarSupply {
  color: white;
  background: #7db53b;
  border: 1px solid #7f7f7f;
  padding: 15px;
  font-size: 1.2rem;
}
.dialog_box {
  height: 300px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  overflow: auto;
  /* justify-content: center; */
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #c1c1c1;
}
.dialog_box div {
  margin-bottom: 15px;
}
.content {
  font-size: 12px;
}
.close {
  position: absolute;
  right: 10px;
  top: 5px;
  border: 0;
}
.title {
  color: #575757;
  font-weight: 500;
  font-size: 1rem;
}
.footer_Cookie {
  display: flex;
  width: 100%;
  height: auto;
  background: #f2f2f2;
  position: fixed;
  bottom: 0;
  align-items: center;
  font-size: 14px;
  z-index: 2001;
  padding: 8px 0;
}

.contactUs-section {
  height: 450px;
  /* background: url("../../public/img/contontUs_bg.webp") no-repeat center; */
  background-size: cover;
  text-align: center;
  color: #ffffff;
}
.section-content {
  /* margin-top: 5rem; */
  height: 80%;
  padding: 6rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.contactUs-section .section-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}
.section-btn {
  margin-top: 50px !important;
}
* a {
  color: inherit;
}
.contact-btn {
  width: 161px;
  height: 37px;
  margin: 1rem auto;
  border-radius: 5px;
  background: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white !important;
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.footer-section .solar-conatct-box {
  padding: 24px 0 2rem 0;
}

img {
  vertical-align: middle;
  border-style: none;
}
.solar-contact-content {
  color: white !important;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area p {
  opacity: 0.8;
  font-size: 16px;
  /* line-height: 40px; */
  color: #ffffff;
  margin-top: 25px;
}
</style>
<template>
  <div class="form-items-container">
    <el-form
      v-if="formData.length"
      ref="form"
      :model="form"
      :label-width="labelWidth"
      :label-position="labelPosition"
      :rules="rules"
    >
      <template v-for="(item, index) in processedFormData">
        <el-form-item
          :key="`forms-${index}`"
          :label="item.label"
          :prop="item.prop"
          :class="[item.wholeLine ? 'is-whole' : '', item.type === 'title' ? 'form-title' : '']"
          :style="{ width: item.width }"
          v-if="!item.isHide"
        >
          <template v-if="item.type === 'input'">
            <el-input
              v-model="form[item.prop]"
              
              :disabled="item.disabled"
            ></el-input>
            <div class="form-tips" v-if="item.tip">{{ item.tip }}</div>
          </template>
          <template v-else-if="item.type === 'select'">
            <el-select
              v-model="form[item.prop]"
              filterable
              clearable
            >
              <el-option
                v-for="(it, i) in item.options"
                :key="`${item.prop}-${i}`"
                :label="it.label"
                :value="it.value"
              ></el-option>
            </el-select>
            <div class="form-tips" v-if="item.tip">{{ item.tip }}</div>
          </template>
          <template v-else-if="item.type === 'password'">
            <el-input
              type="password"
              v-model="form[item.prop]"
              :placeholder="`please enter ${item.label}`"
              :disabled="item.disabled"
            ></el-input>
          </template>
          <template v-else-if="item.type == 'select'">
            <el-select
              v-model="form[item.prop]"
              :placeholder="`please select ${item.label}`"
              filterable
            >
              <el-option
                v-for="(it, i) in item.options"
                :key="`${item.prop}-${i}`"
                :label="it.label"
                :value="it.value"
              ></el-option>
            </el-select>
            <div class="form-tips" v-if="item.tip">{{ item.tip }}</div>
          </template>
          <template v-else-if="item.type === 'date'">
            <el-date-picker
              v-model="form[item.prop]"
              type="date"
              :placeholder="`please select ${item.label}`"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </template>
          <template v-else-if="item.type === 'checkbox'">
            <template v-if="item.options && item.options.length < 2">
              <el-checkbox
                class="single-checkbox"
                v-model="form[item.prop]"
                @change="handleChange($event, item.prop, form, item)"
                >{{
                  item.options.length ? item.options[0].label : ""
                }}</el-checkbox
              >
            </template>
            <template v-else>
              <el-checkbox-group v-model="form[item.prop]">
                <el-checkbox
                  v-for="(it, i) in item.options"
                  :label="it.label"
                  :key="`${item.prop}-${i}`"
                  >{{ it.value }}</el-checkbox
                >
              </el-checkbox-group>
            </template>
          </template>
        </el-form-item>
      </template>
      <el-form-item class="is-whole" v-if="showBtns">
        <div class="btn-group">
          <el-button @click="cancel">Cancel</el-button>
          <el-button type="primary" @click="onSave" :loading="btn.loading"
            >Save</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getOrderById } from "@/api/api";
export default {
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Array,
      default: () => [],
    },
    labelWidth: {
      type: String,
      default: "100px",
    },
    showBtns: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default: () => {},
    },
    labelPosition: {
      type: String,
      default: "right",
    },
  },
  watch: {
    order(newValue) {
      this.order = newValue;
    },
  },
  data() {
    return {
      form: {},
      btn: {
        loading: false,
      },
      order_type: null,
    };
  },
  computed: {
    processedFormData() {
      if (this.formData.length === 0) return [];
      const formDataCopy = [...this.formData];
      formDataCopy.forEach(item => {
        if (item.prop === 'state') {
          item.type = 'select';
          item.options = [
            { label: 'Alabama', value: 'AL' },
            { label: 'Alaska', value: 'AK' },
            { label: 'American Samoa', value: 'AS' },
            { label: 'Arizona', value: 'AZ' },
            { label: 'Arkansas', value: 'AR' },
            { label: 'California', value: 'CA' },
            { label: 'Colorado', value: 'CO' },
            { label: 'Connecticut', value: 'CT' },
            { label: 'Delaware', value: 'DE' },
            { label: 'District of Columbia', value: 'DC' },
            { label: 'Federated States of Micronesia', value: 'FM' },
            { label: 'Florida', value: 'FL' },
            { label: 'Georgia', value: 'GA' },
            { label: 'Guam', value: 'GU' },
            { label: 'Hawaii', value: 'HI' },
            { label: 'Idaho', value: 'ID' },
            { label: 'Illinois', value: 'IL' },
            { label: 'Indiana', value: 'IN' },
            { label: 'Iowa', value: 'IA' },
            { label: 'Kansas', value: 'KS' },
            { label: 'Kentucky', value: 'KY' },
            { label: 'Louisiana', value: 'LA' },
            { label: 'Maine', value: 'ME' },
            { label: 'Marshall Islands', value: 'MH' },
            { label: 'Maryland', value: 'MD' },
            { label: 'Massachusetts', value: 'MA' },
            { label: 'Michigan', value: 'MI' },
            { label: 'Minnesota', value: 'MS' },
            { label: 'Missouri', value: 'MO' },
            { label: 'Montana', value: 'MT' },
            { label: 'Nebraska', value: 'NE' },
            { label: 'Nevada', value: 'NV' },
            { label: 'New Hampshire', value: 'NH' },
            { label: 'New Jersey', value: 'NJ' },
            { label: 'New Mexico', value: 'NM' },
            { label: 'New York', value: 'NY' },
            { label: 'North Carolina', value: 'NC' },
            { label: 'North Dakota', value: 'ND' },
            { label: 'Northern Mariana Islands', value: 'MP' },
            { label: 'Ohio', value: 'OH' },
            { label: 'Oklahoma', value: 'OK' },
            { label: 'Oregon', value: 'OR' },
            { label: 'Palau', value: 'PW' },
            { label: 'Pennsylvania', value: 'PA' },
            { label: 'Puerto Rico', value: 'PR' },
            { label: 'Rhode Island', value: 'RI' },
            { label: 'South Carolina', value: 'SC' },
            { label: 'South Dakota', value: 'SD' },
            { label: 'Tennessee', value: 'TN' },
            { label: 'Texas', value: 'TX' },
            { label: 'Utah', value: 'UT' },
            { label: 'Vermont', value: 'VT' },
            { label: 'Virgin Islands', value: 'VI' },
            { label: 'Virginia', value: 'VA' },
            { label: 'Washington', value: 'WA' },
            { label: 'West Virginia', value: 'WV' },
            { label: 'Wisconsin', value: 'WI' },
            { label: 'Wyoming', value: 'WY' },
          ];
        }
      });
      if (formDataCopy[0].type === 'title') {
        formDataCopy[0].label = this.order_type === 1 ? "Intended installation address" : "";
      }
      return formDataCopy;
    },
    user() {
      return this.getUserInfoFromCookie() || {};
    },
  },
  mounted() {},
  methods: {
    getUserInfoFromCookie() {
      const userInfoCookie = Cookies.get('userInfo');
      if (userInfoCookie) {
        try {
          const decodedCookie = decodeURIComponent(userInfoCookie);
          const userInfo = JSON.parse(decodedCookie);
          return userInfo;
        } catch (error) {
          console.error('Failed to parse userInfo cookie:', error);
          return null;
        }
      }
      return null;
    },
    async getOrderById() {
      const res = await getOrderById({
        order_id: this.$route.query.id,
      });
      if (res.data.code == 200) {
        this.order_type = res.data.data.order_type;
        this.$refs.form.$refs.form = res.data.data;
      }
    },
    onSave() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.btn.loading = true;
        this.$emit("onSave", this.form, this.btn);
      });
    },
    cancel() {
      this.$emit("close");
    },
    handleChange(val, prop, form, item) {

      if (prop === "checkSameAddress") {
        if (val == true) {
          let billingAddress = {};
          let shippingAddress = {};
          const ShippingAddressForm=localStorage.getItem("ShippingAddressForm");
          if(ShippingAddressForm){
            shippingAddress=JSON.parse(ShippingAddressForm);
          }
          this.form = shippingAddress;
          this.form.checkSameAddress = val;
        } else {
          this.form = { checkSameAddress: val };
        }
      }
      this.$emit("handleChange", val, prop, this.form, item);
    },
    handleChangeCheckbox(val, prop, form, item) {
      this.$emit("handleChangeCheckbox", val, prop, form, item);
    },
  },
  created() {
    // this.getOrderById();
  },
};
</script>
<style lang="less" scoped>
.el-form {
  .form-title {
    width: 100% !important;
    margin-bottom: 12px !important;
    ::v-deep {
      .el-form-item__label {
        font-size: 20px;
        color: #000;
        font-weight: bold;

        white-space: nowrap;
      }
    }
  }
  .el-form-item {
    &.is-whole {
      width: 100%;
      .single-checkbox {
        display: flex;
      }
      .form-tips {
        white-space: nowrap;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    .form-tips {
      color: #757575;
      font-size: 12px;
      position: absolute;
      left: 0;
      top: 42px;
      line-height: 16px;
    }
  }
  .el-select,
  .el-input,
  .el-checkbox {
    width: 100%;
  }
  .el-checkbox {
    // display: flex;
    // align-items: flex-start;
    ::v-deep {
      .el-checkbox__label {
        white-space: normal;
        word-wrap: break-word;
      }
      .el-checkbox__input {
        position: relative;
        bottom: -2px;
      }
    }
  }
  .btn-group {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 1000px) {
  .el-form .el-form-item {
    width: 100% !important;
  }
}
</style>
  
<template>
  <div class="page-container">
    <div class="page-main-content">
      <div class="container">
        <div
          class="page-nav"
          v-if="
            $route.name !== 'dashboard' &&
            $route.name !== 'messages' &&
            $route.name !== 'login'
          "
        >
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/dashboard' }">
              Dashboard
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="$route.path == '/order_detail' && (order_type.includes(0) || order_type.includes(1) || order_type.includes(2))">
              <span
                class="orders"
                @click="$router.push('/orders/0')"
                v-if="order_type.includes(0)"
              >
                My Product Orders
              </span>
              <span
                class="orders"
                @click="$router.push('/designOrder/1')"
                v-else-if="order_type.includes(1)"
              >
                My Design Orders
              </span>
              <span
                class="orders"
                @click="$router.push('/SolutionOrders/2')"
                v-else-if="order_type.includes(2)"
              >
                My Solution Orders
              </span>
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="$route.meta && $route.meta.pageName">
              {{ $route.meta.pageName }}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { postMessage, postMessageRead } from "@/api/api";
import Cookies from 'js-cookie';

export default {
  props: {
    desc: {
      type: String,
      default: () => "",
    },
    order_type: {
      type: Array,
      default: () => [], // 设置默认值为一个空数组
    },
  },
  data() {
    return {
      messageList: [],
      messageCount: 0,
    };
  },
  computed: {
    currentYear() {
      const date = new Date();
      return date.getFullYear();
    },
  },
  created() {
    // if (this.$route.name !== "login") {
    //   this.getPostMessage();
    // }
  },
  methods: {
    getUserInfoFromCookie() {
      const userInfoCookie = Cookies.get('userInfo');
      if (userInfoCookie) {
        try {
          const decodedCookie = decodeURIComponent(userInfoCookie);
          const userInfo = JSON.parse(decodedCookie);
          return userInfo;
        } catch (error) {
          console.error('Failed to parse userInfo cookie:', error);
          return null;
        }
      }
      return null;
    },
    jumpPage(url) {
      if (url) {
        this.$router.push(url);
      }
    },
    async getPostMessage() {
      const user = this.getUserInfoFromCookie();
      if (!user) {
        this.$router.push("/VerificationLogin");
        return;
      }
      const res = await postMessage({
        user_id: user.uuid,
      });
      if (res?.data?.code == 200) {
        this.messageList = res.data.data;
        this.messageCount = res.data.false_count;
      } else {
        this.messageList = [];
        this.messageCount = 0;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.orders {
  cursor: pointer;
}
.page-container {
  color: #333;
  .container {
    padding: 0 !important;
    .row {
      padding: 0 !important;
      margin: 0 !important;
      > div {
        padding: 0 10px !important;
      }
    }
  }
  .page-fixed {
    position: fixed;
    left: 0;
    z-index: 1000;
    width: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    background: #fff;

    &.page-header {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    &.page-footer {
      top: auto;
      bottom: 0;
      background: #7eb63b;
      display: flex;
      align-items: center;
      height: 30px;
      justify-content: center;
      text-align: center;
      box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      bottom: 0;
      background: #ccc;
      color: #333;
      font-size: 0.75rem;
      height: 30px;
    }
    .page-top {
      background: #7eb63b;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      font-size: 14px;
    }
    .go-home {
      cursor: pointer;
      font-size: 16px;
    }
    .page-language {
      i {
        position: relative;
        bottom: -2px;
      }
    }
    .page-logo {
      height: 60px;
      background: #fff;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .page-logo-image {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 18px;
        white-space: nowrap;

        img {
          display: block;
          height: 50px;
          width: auto;
        }
      }
      .message-icon {
        cursor: pointer;
        position: relative;
        bottom: -4px;
        .icon-xiaoxi {
          font-size: 36px;
        }
        .message-badge {
          cursor: pointer;
          position: absolute;
          top: -7px;
          left: 16px;
          padding: 4px 4px;
          background-color: red;
          color: white;
          border-radius: 50%;
          min-width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 12px;
            transform: scale(0.8);
          }
        }
      }
    }
  }

  .page-main-content {
    background-color: #f8f8f8;
    padding: 20px 0;
    .container {
      min-height: 100%;
      display: flex;
      flex-flow: column;
    }
  }
  ::v-deep .el-breadcrumb {
    .el-breadcrumb__inner {
      color: #333;
      line-height: 28px;
      font-size: 16px;
      &.is-link {
        color: #333;
        cursor: pointer;
        font-weight: normal;
        &:hover {
          color: #7eb63b;
        }
      }
    }
  }
  .page-nav {
    margin-bottom: 16px;
  }
  .page-title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}
.el-button {
  color: #7eb63b;
  span {
    color: #7eb63b;
  }
}
</style>
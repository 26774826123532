import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import Cookies from 'js-cookie';
import { login, loginWithPhone, loginWithEmail, generateCode, getCodeByEmail } from "@/api/api";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // sessionStorage.getItem('product')
    selectedProduct:  Cookies.get("product") ? JSON.parse(Cookies.get("product")) : null,
    cart: Cookies.get("cart") ? JSON.parse(Cookies.get("cart")) : [],
    user: null,
    userName: Cookies.get('userName'),
    token: Cookies.get('token'),
    tokenExpireTime: null, // 添加一个属性来记录过期时间 
    headerList:[],
    pageMenus:[],
    //是否移动端
    isMobile:false
  },
  mutations: {
    setIsMobile(state,product){
    
      state.isMobile = product
    },
    setSelectedProduct(state, product) {
      // sessionStorage.setItem('product', JSON.stringify(product));
      Cookies.set("product",JSON.stringify(product))
      state.selectedProduct = product;
    },
    addToCart(state, product) {
      const existingProduct = state.cart.find(item => item.uuid === product.uuid);
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        product.quantity = 1;
        state.cart.push({ ...product });
      }
      // sessionStorage.setItem('cart', JSON.stringify(state.cart)); 
      Cookies.set("cart",JSON.stringify(state.cart))
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
      // localStorage.setItem('token', token);
      Cookies.set("token",token)
    },
    setTokenExpireTime(state, expireTime) {
      state.tokenExpireTime = expireTime;
    },
    setPathItme(state, pathItme){
      state.pathItme = pathItme;
    },
    logout(state) {
      state.user = null;
      state.token = null;
      // localStorage.removeItem('token');
      // sessionStorage.removeItem('product');
      // sessionStorage.removeItem('cart');
      Cookies.remove('token');
      Cookies.remove('product');
      Cookies.remove('cart');
      state.tokenExpireTime = null; // 清除过期时间
    },
    setError(state, error) {
      state.error = error;
    },
    setHeaderList(state, headerList){
      state.headerList = headerList;
    },
    setPageMenus(state, data){
      state.pageMenus = data;
    },
  },
  actions: {
    async fetchProductDetails({ commit }, productId) {
      try {
        const response = await fetch(`https://api.example.com/products/${productId}`);
        const data = await response.json();
        commit('setSelectedProduct', data);
        commit('setError', null); // 清除之前的错误
      } catch (error) {
        console.error('Error fetching product details:', error);
        commit('setError', error.message);
      }
    },
    async headerList({ commit },content) {
      let data = await postRouterPath(content);
      if (data.code == 200) {
        commit('setHeaderList', data.data);
      }
    },
    addProductToCart({ commit }, product) {
      commit('addToCart', product);
    },
    async login({ commit }, { username, password }) {
      try {
        const response = await login(username, password);
        const { user, token, expireTime } = response.data;
        if (user) {
          commit('setUser', user);
          commit('setToken', token);
          commit('setTokenExpireTime', expireTime);
          this.dispatch('startLogoutTimer'); // 启动登录超时定时器
        } else {
          commit('setUser', null);
        }
      } catch (error) {
        console.error('Login failed:', error);
        commit('setError', error.response ? error.response.data.message : error.message);
      }
    },
    async loginWithPhone({ commit }, { phone, code }) {
      try {
        const response = await loginWithPhone(phone, code);
        const { user, token, expireTime } = response.data;
        if (user) {
          commit('setUser', user);
          commit('setToken', token);
          commit('setTokenExpireTime', expireTime);
          this.dispatch('startLogoutTimer'); // 启动登录超时定时器
        } else {
          commit('setUser', null);
        }
      } catch (error) {
        console.error('Login with phone failed:', error);
        commit('setError', error.response ? error.response.data.message : error.message);
      }
    },
    async loginWithEmail({ commit }, { email, code }) {
      try {
        const response = await loginWithEmail(email, code);
        const { user, token, expireTime } = response.data;
        if (user) {
          commit('setUser', user);
          commit('setToken', token);
          commit('setTokenExpireTime', expireTime);
          this.dispatch('startLogoutTimer'); // 启动登录超时定时器
        } else {
          commit('setUser', null);
        }
      } catch (error) {
        console.error('Login with email failed:', error);
        commit('setError', error.response ? error.response.data.message : error.message);
      }
    },
    startLogoutTimer({ state, dispatch }) {
      if (state.tokenExpireTime) {
        const timeRemaining = state.tokenExpireTime - Date.now();
        if (timeRemaining > 0) {
          setTimeout(() => {
            dispatch('logout');
          }, timeRemaining);
        }
      }
    },
  },
  getters: {
    getCartItems: state => state.cart,
    isLoggedIn: state => !!state.user,
    getToken: state => state.token,
  }
});